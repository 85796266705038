
.news-header,
.news-content,
.news-intro {
  max-width: 740px;
  margin: 0 auto;
}
.news-content {
  .wysiwyg {
    div.image {
      max-width: 740px;
      margin: 40px auto;

      img {
        width: 100%;
      }
    }

    div:not(.image) {
      max-width: 484px;
      margin: 20px auto;
    }   

    div.slogan,
    div.slogan p {
        font-family: $font-serif;
        font-style: italic;
        font-size: 20px;
        line-height: 28px;
    }
    
    div.slogan-red {
        color: $color-red;
        text-align: right;
      }

    div.slogan-black {
      color: $color-black;
    }
  }
}


.news {
  &-header {
    h1 {
      font-family: $font-serif;
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 30px;
      font-weight: 400;
      // text-wrap: balance;
    }
  }

  &-intro {
    &-date {
      margin-bottom: 20px;
    }

    &-inner {
      .wysiwyg {
        margin-bottom: 0;
      }
    }
  }

  &-more {
    h3 {
      font-weight: 400;
      font-size: 20px;
    }
    &-list {
      border-top: 1px solid $color-black;
      padding: 30px 0 0;
      list-style-type: none;
      margin: 0;
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;

      .news-item {
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (min-width: $screen-tab) {
  .news {
    &-header {
      h1 {
        font-size: 34px;
        line-height: 44px;
        margin: 0 0 40px;
      }
    }

    &-intro {
      &-inner {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }
    }
 
    &-more {
      h3 {
        font-size: 22px;
      }
      &-list {        
        padding: 40px 0 0;
        grid-template-columns: repeat(3, 1fr);
  
        .news-item {
          margin-bottom: 40px;
        }
      }
    }

    &-content {
      .wysiwyg {
        div.slogan,
        div.slogan p {
            font-style: italic;
            font-size: 24px;
            line-height: 32px;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-desktop) {
  .news { 
    &-header {
      h1 {
        font-size: 48px;
        line-height: 60px;
      }
    }
    &-more {
      h3 {
        font-size: 24px;
      }
      &-list {       
        grid-template-columns: repeat(5, 1fr);

        /*.news-item {
          &:last-of-type {
            display: none;
          }
        }*/
      }
    }
  }
}